:root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
    --card: 0 0% 100%;
    --card-border: 214.3 31.8% 91.4%;
    --card-border-tint: 112 205 159;
    --card-foreground: 222.2 47.4% 11.2%;
    --widget: 0 0% 97%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --primary: 95.08 71.08% 67.45%;
    --primary-foreground: 95.08 71.08% 10%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
    --ring: 95.08 71.08% 67.45%;
    --radius: 0.5rem;
}
/* body *{
   font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: normal;
} */

.roboto-thin {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .roboto-thin-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .roboto-light {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .roboto-light-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .roboto-regular {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .roboto-regular-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .roboto-medium {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .roboto-medium-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .roboto-bold {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .roboto-bold-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .roboto-black {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .roboto-black-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: italic;
  }
.bg-primary {
    background-color: hsl(var(--primary));
}
.bg-muted {
    background-color: hsl(var(--muted));
}
.text-muted-foreground {
    color: hsl(var(--muted-foreground));
}
.bg-background {
    background-color: hsl(var(--background));
}
.border-muted {
    border-color: hsl(var(--muted));
}

.btnaccent:hover {
    color: hsl(var(--accent-foreground));
    background-color: hsl(var(--accent));
}


.banner-cta {
    display: inline-flex;
    width: 370px;
    background: #FFF;
    position: relative;
    overflow: hidden;
    margin: 0 0 .4em 0;
}

.banner-cta_slide {
    width: 100%;
    height: 100%;
    left: -366px;
    background: #ff9d00;
    position: absolute;
    transition: all .25s ease-Out;
    bottom: 0;
}

.banner-cta a {
    color: #00adff;
    padding: .5em 20px;
    width: 370px;
    text-decoration: none;
    position: relative;
}

.banner-cta:hover .banner-cta_slide {
    left: 0;
}
.banner-cta:hover a {
    color: #fff;
    border-radius: 1em;
}
.highlighter {
    background: rgba(0, 173, 255, .2);
}
.legal ul li{
  display: flex;
  justify-items: center;
  gap: 2px;
  position: relative;
}

.legal ul li::before{
  content: '▣';
  margin-right: 5px;
}
.dropdown-toggle.not-dropdown::after{
  display: none;
}




