@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .no-scrollbar::-webkit-scrollbar{
        display: none;
    }

    .no-scrollbar{
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}